import revive_payload_client_rQvjBzFLNH from "/builds/dotykacka/designex-group/dg-client-zone/node_modules/.pnpm/nuxt@3.14.159_@types+node@20.17.6_eslint@9.14.0_rollup@4.24.4_typescript@5.1.3_vite@5.4.10/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_1DVm1UV4mt from "/builds/dotykacka/designex-group/dg-client-zone/node_modules/.pnpm/nuxt@3.14.159_@types+node@20.17.6_eslint@9.14.0_rollup@4.24.4_typescript@5.1.3_vite@5.4.10/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_TzQusN6lwt from "/builds/dotykacka/designex-group/dg-client-zone/node_modules/.pnpm/nuxt@3.14.159_@types+node@20.17.6_eslint@9.14.0_rollup@4.24.4_typescript@5.1.3_vite@5.4.10/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_0QxWSSdJAB from "/builds/dotykacka/designex-group/dg-client-zone/node_modules/.pnpm/nuxt-site-config@2.2.21_rollup@4.24.4_vite@5.4.10_vue@3.5.12/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_HMfdjU3hwT from "/builds/dotykacka/designex-group/dg-client-zone/node_modules/.pnpm/nuxt@3.14.159_@types+node@20.17.6_eslint@9.14.0_rollup@4.24.4_typescript@5.1.3_vite@5.4.10/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_TP1p6xuJk8 from "/builds/dotykacka/designex-group/dg-client-zone/node_modules/.pnpm/nuxt@3.14.159_@types+node@20.17.6_eslint@9.14.0_rollup@4.24.4_typescript@5.1.3_vite@5.4.10/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_amOkODtOfj from "/builds/dotykacka/designex-group/dg-client-zone/node_modules/.pnpm/nuxt@3.14.159_@types+node@20.17.6_eslint@9.14.0_rollup@4.24.4_typescript@5.1.3_vite@5.4.10/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_SjrdeJrq8b from "/builds/dotykacka/designex-group/dg-client-zone/node_modules/.pnpm/nuxt@3.14.159_@types+node@20.17.6_eslint@9.14.0_rollup@4.24.4_typescript@5.1.3_vite@5.4.10/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/builds/dotykacka/designex-group/dg-client-zone/.nuxt/components.plugin.mjs";
import prefetch_client_GjDfw1R3MB from "/builds/dotykacka/designex-group/dg-client-zone/node_modules/.pnpm/nuxt@3.14.159_@types+node@20.17.6_eslint@9.14.0_rollup@4.24.4_typescript@5.1.3_vite@5.4.10/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_Y8zP3ILPxI from "/builds/dotykacka/designex-group/dg-client-zone/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_rollup@4.24.4_vue@3.5.12/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import _01_sentry_client_2rh1pDAIty from "/builds/dotykacka/designex-group/dg-client-zone/plugins/01.sentry.client.ts";
import _02_firebase_client_4c9KdheLb8 from "/builds/dotykacka/designex-group/dg-client-zone/plugins/02.firebase.client.ts";
import _03_firebaseui_client_2DeIH0F1by from "/builds/dotykacka/designex-group/dg-client-zone/plugins/03.firebaseui.client.ts";
import _04_functions_client_8C4voHcNsM from "/builds/dotykacka/designex-group/dg-client-zone/plugins/04.functions.client.ts";
import _05_qrcode_client_nixZao7HuG from "/builds/dotykacka/designex-group/dg-client-zone/plugins/05.qrcode.client.ts";
import _06_vue_3_sanitize_client_YBWgHnW9Av from "/builds/dotykacka/designex-group/dg-client-zone/plugins/06.vue-3-sanitize.client.ts";
export default [
  revive_payload_client_rQvjBzFLNH,
  unhead_1DVm1UV4mt,
  router_TzQusN6lwt,
  _0_siteConfig_0QxWSSdJAB,
  payload_client_HMfdjU3hwT,
  navigation_repaint_client_TP1p6xuJk8,
  check_outdated_build_client_amOkODtOfj,
  chunk_reload_client_SjrdeJrq8b,
  components_plugin_KR1HBZs4kY,
  prefetch_client_GjDfw1R3MB,
  i18n_Y8zP3ILPxI,
  _01_sentry_client_2rh1pDAIty,
  _02_firebase_client_4c9KdheLb8,
  _03_firebaseui_client_2DeIH0F1by,
  _04_functions_client_8C4voHcNsM,
  _05_qrcode_client_nixZao7HuG,
  _06_vue_3_sanitize_client_YBWgHnW9Av
]